
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "finance-payment-details",
  components: {
    GoBackButton
  },
  props: ["publicId"],
  data() {
    return {
      payment: {},
      metadata: {}
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Details", ["Payment"]);
    });
  },
  created() {
    this.getPaymentDetails();

    //Set page title
    document.title = "Payment Details | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    getPaymentDetails() {
      //Fetch the payment details of the publicId
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.FINANCE_PAYMENT_DETAILS_ROUTE}/${this.publicId}`
        )
          .then(({ data }) => {
            //Assign data to form fields
            this.payment = data.data;
            this.metadata = data.data.metadata.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    capitalize(value) {
      return variables.capitalize(value);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    gotoUrl(event) {
      this.$router.push(event);
    }
  }
});
